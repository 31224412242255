// 외부 모듈
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Flex, Header, Menu } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { version } from 'package.json';

// 내부 모듈
import Sales from './desktop/Sales';
import VmState from './desktop/VmState';
import Profile from './desktop/Profile';
import PaycoPayments from './desktop/PaycoPayments';
import VmUsedAmount from './desktop/VmUsedAmount';
import AddNewRecipeAndIngredient from './desktop/AddNewRecipeAndIngredient';
import GetAndUpdateRecipe from './desktop/GetAndUpdateRecipe';
import FSTSales from './desktop/FSTSales';
import VmDashboard from './desktop/VmDashboard';
import VmAvailableRecipes from './desktop/VmAvailableRecipes';

// 타입
import { RootStateType } from '@reducers/index';
import BenefitControl from './desktop/benefitContorl/index';
import ContainerCheck from './desktop/ContainerCheck';
import WalletControl from './desktop/walletControl';
import RecipesOrder from './desktop/recipeOrderControl';
import ProdPrice from './desktop/prodPriceControl';
import CorpWalletChargeHistory from './desktop/CorpWalletChargeHistory';
import CreateVm from './desktop/CreateVm';
import TaskManager from './desktop/taskManager';
import Monitoring from './desktop/monitoringControl';
import History from './desktop/historyControl';
import { Admin } from '@definitions/admins';
import Test from './desktop/Test';

const menu = [
  '매출자료',
  '기기상태',
  '관리자 프로필',
  '페이코 결제기록',
  'fst 정산',
  '원액 소모량 취합',
  '레시피 및 원액 추가',
  '레시피 조회 및 업데이트',
  'Benefit(Members,VmRecips)',
  '대시보드',
  '컨테이너 검증',
  '지갑관리',
  '장소별 판매가능 레시피',
  '레시피 순서 변경',
  '레시피 공급가액 변경',
  '법인지갑충전자료',
  'VM생성',
  'TASK MANAGER',
  '모니터',
  '모니터링 항목별 기록',
  '테스트 기능(개발팀)',
];

function printMain(selectedMenuName: string) {
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);

  if (!admins || !uid) {
    return null;
  }
  if (admins[uid].level > 1) {
    if (selectedMenuName === menu[11]) {
      return <WalletControl />;
    }
    return null;
  }

  switch (selectedMenuName) {
    case menu[0]:
      return <Sales admin={admins[uid]} />;
    case menu[1]:
      return <VmState />;
    case menu[2]:
      return <Profile />;
    case menu[3]:
      return <PaycoPayments />;
    case menu[4]:
      return <FSTSales />;
    case menu[5]:
      return <VmUsedAmount />;
    case menu[6]:
      return <AddNewRecipeAndIngredient />;
    case menu[7]:
      return <GetAndUpdateRecipe />;
    case menu[8]:
      return <BenefitControl />;
    case menu[9]:
      return <VmDashboard />;
    case menu[10]:
      return <ContainerCheck />;
    case menu[11]:
      return <WalletControl />;
    case menu[12]:
      return <VmAvailableRecipes />;
    case menu[13]:
      return <RecipesOrder />;
    case menu[14]:
      return <ProdPrice />;
    case menu[15]:
      return <CorpWalletChargeHistory />;
    case menu[16]:
      return <CreateVm />;
    case menu[17]:
      return <TaskManager />;
    case menu[18]:
      return <Monitoring />;
    case menu[19]:
      return <History />;
    case menu[20]:
      return <Test />;
    default:
      return <div>loadding...</div>;
  }
}

const initMenu = (admin: Admin, setMenu: (menu: string[]) => void, setSelectedMenuName: (name: string) => void) => {
  if (admin.type === 'FRANCHISEE') {
    setMenu(['매출자료']);
    setSelectedMenuName('매출자료');
  } else if (admin.level > 1) {
    setMenu(['지갑관리']);
    setSelectedMenuName('지갑관리');
  } else {
    setMenu(menu);
    setSelectedMenuName('매출자료');
  }
};

function MainDesktop(): JSX.Element {
  useFirestoreConnect(() => [
    { collection: 'version/v3/ingredients' },
    { collection: 'version/v3/containers' },
    { collection: 'version/v3/admins' },
    { collection: 'version/v3/vms' },
    { collection: 'version/v3/recipes' },
    { collection: 'version/v3/members' },
  ]);
  const [selectedMenuName, setSelectedMenuName] = useState<string>('');
  const [menu, setMenu] = useState<string[]>([]);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const { sagaInit } = useSelector((state: RootStateType) => state);
  useEffect(() => {
    admins && uid && initMenu(admins[uid], setMenu, setSelectedMenuName);
  }, [sagaInit]);

  return (
    <div css={main__wrap}>
      <Flex style={{ height: '100%' }}>
        <Flex gap="gap.small" column style={{ width: '300px', paddingLeft: '16px' }}>
          <Header content="플랜즈커피" description={`데스트톱용 관리자화면 ${version}`} />
          {sagaInit ? (
            <Menu
              defaultActiveIndex={menu.findIndex((m) => m === '지갑관리')}
              onActiveIndexChange={(item) => setSelectedMenuName(item.target.innerText)}
              vertical
              pointing
              style={{ marginTop: '40px' }}
              items={menu}
            />
          ) : (
            <div>loadding...</div>
          )}
        </Flex>
        <Flex column fill>
          {printMain(selectedMenuName)}
        </Flex>
      </Flex>
    </div>
  );
}

const main__wrap = css`
  width: 100vw;
  height: 100vh;
  font-family: Open Sans;
  display: flex;
  flex-direction: column;
  header {
    height: 160px;
    width: 100vw;
    background-color: white;
    font-family: Times Sans Serif;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-bottom: 1px solid #666666;
  }
  nav {
    width: 100%;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-top: solid 1px #666666;
    z-index: 1;
  }
`;
export default MainDesktop;
