// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import { MountedContainer } from '@definitions/container';
import { RootStateType } from '@reducers';
import { useSelector } from 'react-redux';

// 내부 모듈
import VmAmountCard from './VmAmountCard';
import VmDashboardTable from './VmDashboardTable';
import { VendingMachine } from '@definitions/vm';

// 상수
import { DEPRICATED_VMS } from '@constants/vm';

const excludeVmFilter = (vm: VendingMachine) => !DEPRICATED_VMS.includes(vm.id);

function VmDashboard(): JSX.Element {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);

  // dashboard에는 test,init,사용하지않는 vm은 빼고 보여준다
  const filterValidVms = vms.filter(excludeVmFilter);

  return (
    <div css={dashboard__wrap}>
      <div css={card__wrap}>
        {filterValidVms.map((item, idx) => (
          <VmAmountCard key={idx} item={item} ingredients={ingredients} />
        ))}
      </div>
      <div css={table__wrap}>
        <VmDashboardTable vms={filterValidVms} ingredients={ingredients} />
      </div>
    </div>
  );
}

export default VmDashboard;

const dashboard__wrap = css``;
const card__wrap = css`
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0 0 -16px;
  width: calc(100% + 16px);
  > * {
    margin: 16px 0 0 16px;
  }
`;

const table__wrap = css`
  margin-top: 30px;
  margin-bottom: 30px;
`;
