import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import MonitoringTable from './MonitoringTable';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import axios from 'axios';
import { Monitoring } from '@definitions/monitoring';
import { DEPRICATED_VMS } from '@constants/vm';

// 테이블
const EVENT_KEY_TABLE: { [key: string]: string } = {
  HOT_ONLY: '뜨거운 음료 중지',
  ICED_ONLY: '아이스 음료 중지',
  TOUCH_LEFT: '왼쪽 터치 안됨',
  TOUCH_RIGHT: '오른쪽 터치 안됨',
  SOLDOUT_ALL: '솔드아웃 발생',
  SOLDOUT_MAIN: '주요 메뉴 솔드아웃',
  TIMEOUT: '응답 없음(끊김)',
};

// 데이터를 변환하는 함수
const transformData = (data: any) => {
  const vmIds = new Set();
  const keys: string[] = Object.keys(data);

  // 모든 vmId 수집
  keys.forEach((key) => {
    Object.keys(data[key]).forEach((vmId) => {
      vmIds.add(vmId);
    });
  });

  // vmId별로 데이터를 정리
  const transformedData = Array.from(vmIds).map((vmId: any) => {
    const row = { vmId };
    keys.forEach((key) => {
      if (data[key][vmId]) {
        row[key] = data[key][vmId];
      } else {
        row[key] = { totalDuration: '-', ratio: '-' }; // 데이터가 없을 경우 표시
      }
    });
    return row;
  });

  return { keys, transformedData };
};

function OperationControl(): JSX.Element {
  const [events, setEvents] = useState<{ keys: string[]; data: any[] }>({ keys: [], data: [] });
  useEffect(() => {
    const addr = `http://planz-proxy.com:3000/getEvents`;
    const params = { key: 'planz!3245' };
    axios.get(addr, { params }).then((res) => {
      //   console.log(res);
      const { keys, transformedData } = transformData(res.data);
      setEvents({ keys, data: transformedData });
    });
  }, []);
  return (
    <Container maxWidth="xl" style={{ marginTop: '19px', overflow: 'scroll' }}>
      <Typography variant="h4" gutterBottom>
        운영현황({new Date().toLocaleDateString()})
      </Typography>
      <DataTable keys={events.keys.filter((key) => Object.keys(EVENT_KEY_TABLE).includes(key))} data={events.data} />
    </Container>
  );
}
const formatDuration = (milliseconds: number) => {
  const totalSeconds = milliseconds / 1000;
  const totalMinutes = totalSeconds / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return `${hours}시간 ${minutes}분`;
};

const DataTable = ({ keys, data }: { keys: string[]; data: any[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">vmId</TableCell>
            {keys.map((key) => (
              <TableCell align="center" key={key}>
                {EVENT_KEY_TABLE[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.vmId}>
              <TableCell align="center">{row.vmId}</TableCell>
              {keys.map((key) => (
                <TableCell align="center" key={key}>
                  <div>
                    {row[key].totalDuration !== '-' ? formatDuration(row[key].totalDuration) : ' '}(
                    {row[key].ratio !== '-' ? `${(row[key].ratio * 100).toFixed(0)}%` : '-'})
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperationControl;
